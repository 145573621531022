import * as React from "react";
import * as styles from "./about-us.module.css";

import { Box, Button } from "@chakra-ui/react";
import { FunctionComponent, useCallback, useState } from "react";

import ContactForm from "../components/Contact/ContactForm";
import Layout from '../components/Base/Layout';
import { SEO } from "../components/Base/Seo";
import { navigate } from "gatsby";
import useAuth from '../components/useAuth';

const AboutUs: FunctionComponent = () => {

  const { loggedIn } = useAuth();

  const onButton6Click = useCallback(() => {
    navigate("/shop");
  }, []);

  return (
    <Layout>
      <div className={styles.aboutUs}>

        <div className={styles.aboutUsIntro}>
          <div className={styles.aboutUs1}>
            <div className={styles.aboutUsText}>
              <div className={styles.title}>
                <b className={styles.display}>About Us</b>
              </div>
              <div className={styles.heroText}>
                <div className={styles.textBox}>
                  <div className={styles.p}>
                    <div className={styles.caption}>
                      CDN Health is a top global provider of pharmaceuticals and medical equipment and devices. Offering healthcare solutions for various facilities, including medical spas, clinics, pharmacies, dermatology practices, and more.
                    </div>
                  </div>
                  <div className={styles.p}>
                    <div className={styles.caption}>
                      Thanks to our broad network of international partners and suppliers, we can offer medical supplies at competitive prices. We understand the importance of providing quality care to patients for the success of our clients. Thus, we strive to offer a wide range of devices from reputable brands. With fast delivery and reliable service, rely on CDN Health products to help your business thrive.
                    </div>
                  </div>
                  <div className={styles.p}>
                    <div className={styles.caption}>
                      <p className={styles.yourSafetyIs}>
                        As a top priority, we ensure your safety by using SSL encryption to protect your personal information during online transactions on our website.
                      </p>
                      <p className={styles.yourSafetyIs}>&nbsp;</p>
                      <p className={styles.viewOurProduct}>
                        Please browse through our product catalog for competitive prices and trusted brand options.
                      </p>
                      <br></br>
                      {!loggedIn ? <></> : <>
                        <p className={styles.viewOurProduct}>
                          MARIS INTERNATIONAL LTD (Registration No.: C 78495) <br></br>
                          Address: 152/NO 9, NAXXAR ROAD SAN GWANN, SGN 9030, MALTA
                        </p>
                      </>}
                    </div>
                  </div>

                  <Button
                    onClick={onButton6Click}
                    variant="solid"
                    colorScheme="cdicyan">Browse Products</Button>
                </div>
                <div className={styles.imageAbout}>
                  <img
                    className={styles.imageIcon}
                    alt=""
                    src="../imageAbout.webp"
                  />
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

      <Box py="120px" px="16px" bgImage="url(../contactSection.webp)" backgroundRepeat="no-repeat" backgroundPosition="center" backgroundSize="cover">
        <Box maxW={{ base: "100%", md: "800px" }} margin='0px auto'>
          <ContactForm></ContactForm>
        </Box>
      </Box>

    </Layout>
  );
};

export default AboutUs;

export const Head = () => (
  <SEO title="CDN Health | About us" description="About us." />
)
